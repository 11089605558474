import React, { Suspense, lazy, useEffect } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	Outlet,
} from 'react-router-dom';
import GuardedRoute from 'src/utils/GuardedRoute';
import './icons';
import Alert from 'src/components/alert/Alert';
import { RolesList } from './components/util/RolesList';
import SSOCallback from './components/login/sso/SSOCallback';
import { RedirectRoute } from './utils/RedirectRoute';
import { ClipLoader } from 'react-spinners';

// !Dont change the order of the below three scss files
import 'bootstrap/scss/bootstrap.scss'; //For Bootstrap Import
import './assets/base.scss'; //From babmurg Theme
import './App.scss'; //custom app styles
import GlobalWrapper from './components/GlobalWrapper';

// Lazy Imports
const GeneralNavigation = lazy(() =>
	import('src/quantum_ticketing/GeneralNav/GeneralNavigation'),
);
const Tickets = lazy(() =>
	import('src/quantum_ticketing/MainPage/TicketsTable/tickets'),
);
const UserSideBar = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/UserProfile/UserSideBar'),
);
const Users = lazy(() => import('src/quantum_ticketing/Users/users'));
const Views = lazy(() => import('src/quantum_ticketing/MainPage/Views/Views'));
const AddEditView = lazy(() =>
	import('src/quantum_ticketing/MainPage/Views/AddEditView'),
);
const Reporting = lazy(() =>
	import('src/quantum_ticketing/Reporting/Reporting'),
);
const DocumentCenterRoute = lazy(() =>
	import('src/quantum_ticketing/DocumentCenter/DocumentCenterRoute'),
);
const DocumentCenter = lazy(() =>
	import('src/quantum_ticketing/DocumentCenter/DocumentCenter'),
);
const FaqRoute = lazy(() => import('src/quantum_ticketing/Faq/FaqRoute'));
const FAQArticle = lazy(() => import('src/components/faq/FAQArticle'));
const Faq = lazy(() => import('src/quantum_ticketing/Faq'));
const FaqChangeRequests = lazy(() =>
	import('src/quantum_ticketing/Faq/FaqChangeRequests'),
);
const AddFolder = lazy(() => import('src/quantum_ticketing/Faq/AddFolder'));
const AddSubFolder = lazy(() =>
	import('src/quantum_ticketing/Faq/AddSubFolder'),
);
const AddFaq = lazy(() => import('src/quantum_ticketing/Faq/AddFaq'));
const ReviewFaq = lazy(() => import('src/quantum_ticketing/Faq/ReviewFaq'));
const AdminOptionsDisplay = lazy(() =>
	import(
		'src/quantum_ticketing/Admin/modules/OptionsDisplay/AdminOptionsDisplay'
	),
);
const Emails = lazy(() => import('src/quantum_ticketing/Admin/modules/Emails'));
const AddGroup = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Groups/AddGroup'),
);
const AddRole = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Roles/AddRole'),
);
const AgentsEditOrCreate = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Agents/AgentsEditOrCreate'),
);
const Agents = lazy(() => import('src/quantum_ticketing/Admin/modules/Agents'));
const Workflows = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Workflows'),
);
const Groups = lazy(() => import('src/quantum_ticketing/Admin/modules/Groups'));
const Roles = lazy(() => import('src/quantum_ticketing/Admin/modules/Roles'));
const SignInOptions = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/SignInOptions/SignInOptions'),
);
const AddorUpdateSSO = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/SingleSignOn/AddorUpdateSSO'),
);
const SingleSignOn = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/SingleSignOn/SingleSignOn'),
);
const Security = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Security'),
);
const SatisfactionSurveySettings = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/SatisfactionSurveySettings'),
);
const WhiteListBlackList = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/WhiteListBlackList'),
);
const EndUserPortalConfigurations = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/EndUserPortal/Configurations'),
);
const AddOrEditAnnouncements = lazy(() =>
	import(
		'src/quantum_ticketing/Admin/modules/Announcements/AddOrEditAnnouncements'
	),
);
const Announcements = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Announcements/Announcements'),
);
const QuickResponse = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/QuickResponse'),
);
const Tags = lazy(() => import('src/quantum_ticketing/Admin/modules/Tags'));
const SuperAdmin = lazy(() =>
	import('src/quantum_ticketing/SuperAdmin/SuperAdmin'),
);
const AddEditEnterprise = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Enterprises/AddEditEnterprise'),
);
const Enterprises = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Enterprises'),
);
const DefaultFields = lazy(() =>
	import('src/quantum_ticketing/SuperAdmin/DefaultFields/DefaultFields'),
);
const EditDefaultField = lazy(() =>
	import(
		'src/quantum_ticketing/SuperAdmin/DefaultFields/EditFieldV2/EditDefaultField'
	),
);
const AddDefaultField = lazy(() =>
	import(
		'src/quantum_ticketing/SuperAdmin/DefaultFields/AddDefaultField/AddDefaultField'
	),
);
const SuperAdminAgentsEditOrCreate = lazy(() =>
	import('src/quantum_ticketing/SuperAdmin/Agents/SuperAgentsEditOrCreate'),
);
const SuperAdminAgents = lazy(() =>
	import('src/quantum_ticketing/SuperAdmin/Agents'),
);
const TicketDetailsLayout = lazy(() =>
	import('src/quantum_ticketing/TicketDetails/TicketDetailsLayout'),
);
const GeneralTicketDetails = lazy(() =>
	import('src/quantum_ticketing/TicketDetails/GeneralTicketDetails'),
);
const AdditionalTicketDetails = lazy(() =>
	import('src/quantum_ticketing/TicketDetails/AdditionalTicketDetails'),
);
const TicketMessaging = lazy(() =>
	import('src/quantum_ticketing/TicketDetails/TicketMessaging'),
);
const TicketAttachments = lazy(() =>
	import('src/quantum_ticketing/TicketDetails/TicketAttachments'),
);
const TicketHistoryPage = lazy(() =>
	import('src/quantum_ticketing/TicketDetails/History/TicketHistoryPage'),
);
const UserPage = lazy(() => import('src/quantum_ticketing/Users/userPage'));
const AssignTicketPage = lazy(() =>
	import('src/quantum_ticketing/MainPage/AssignTicket/AssignTicketPage'),
);
const TicketWorkflowListPage = lazy(() =>
	import(
		'src/quantum_ticketing/MainPage/TicketWorkflow/TicketWorkflowListPage'
	),
);
const TicketWorkflowRenderPage = lazy(() =>
	import(
		'src/quantum_ticketing/MainPage/TicketWorkflow/TicketWorkflowRenderPage'
	),
);
const TicketWorkflowExecutionListPage = lazy(() =>
	import(
		'src/quantum_ticketing/MainPage/TicketWorkflow/TicketWorkflowExecutionListPage'
	),
);
const AddTicket = lazy(() =>
	import('src/quantum_ticketing/MainPage/AddTicket/addTicket'),
);
const AccessDenied = lazy(() => import('src/components/util/AccessDenied'));
const NotFound = lazy(() => import('src/quantum_ticketing/notFound'));
const AuthScreen = lazy(() => import('src/auth/AuthScreen'));
const IdpSignIn = lazy(() => import('src/components/login/sso/IdpSignIn'));
const SSOSiginin = lazy(() => import('src/components/SSOSignin/SSOSiginin'));
const SendLink = lazy(() => import('src/auth/SendLink'));
const ChangePassword = lazy(() => import('src/auth/ChangePassword'));
const EndUserHomePage = lazy(() => import('src/components/EndUserHomePage'));
const FAQPage = lazy(() => import('src/components/faq/FAQPage'));
const AgentFAQPage = lazy(() => import('src/components/faq/AgentFAQPage'));
const AnnouncementView = lazy(() =>
	import('src/quantum_ticketing/AnnouncementsView/AnnouncementView'),
);
const EndUserTicket = lazy(() =>
	import('src/components/endUserPortal/ticket/endUserTicket'),
);
const ActivateAccount = lazy(() =>
	import('src/components/endUserPortal/activate/activateAccount'),
);
const ActiveSecondaryEmail = lazy(() =>
	import('src/components/endUserPortal/activateSecondaryEmail'),
);
const Automation = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Automation'),
);
const AddCreationRule = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Automation/AddCreationRule'),
);
const AddChangeRule = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/Automation/AddChangeRule'),
);
const AddEditAutomationRule = lazy(() =>
	import(
		'src/quantum_ticketing/Admin/modules/Automation/AddEditAutomationRule'
	),
);
const CustomForm = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/CustomForm'),
);
const AddCustomForm = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/CustomForm/AddCustomForm'),
);
const CustomFields = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/CustomFields'),
);
const BusinessRules = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/BusinessRules'),
);
const CreateSLA = lazy(() =>
	import('src/quantum_ticketing/Admin/modules/BusinessRules/CreateSLA'),
);
const CreateBusinessHours = lazy(() =>
	import(
		'src/quantum_ticketing/Admin/modules/BusinessRules/CreateBusinessHours/CreateBusinessHours'
	),
);
const AddField = lazy(() =>
	import(
		'src/quantum_ticketing/Admin/modules/CustomFields/AddField/AddField'
	),
);
const EditField = lazy(() =>
	import(
		'src/quantum_ticketing/Admin/modules/CustomFields/EditFieldV2/EditField'
	),
);
const IncidenceResponseLayout = lazy(() =>
	import('src/IncidentResponse/IncidenceResponseLayout'),
);
const GeneralCaseInformation = lazy(() =>
	import(
		'src/IncidentResponse/GeneralCaseInformation/GeneralCaseInformation'
	),
);
const Incidents = lazy(() => import('./IncidentResponse/Incidents/Incidents'));
const CaseDetails = lazy(() =>
	import('./IncidentResponse/CaseDetails/CaseDetails'),
);
const CaseDiscussion = lazy(() =>
	import('./IncidentResponse/CaseDiscussion/CaseDiscussion'),
);
const CaseParticipants = lazy(() =>
	import('./IncidentResponse/CaseParticipants/CaseParticipants'),
);
const Attachments = lazy(() =>
	import('./IncidentResponse/Attachments/Attachments'),
);
const Tasks = lazy(() => import('./IncidentResponse/Tasks/Tasks'));
const CaseNotes = lazy(() => import('./IncidentResponse/CaseNotes/CaseNotes'));
const Synopsis = lazy(() => import('./IncidentResponse/Synopsis/Synopsis'));
const AssignIncidentsPage = lazy(() =>
	import('./IncidentResponse/AssignIncidentspage/AssignIncidentsPage'),
);
const ChatbotSettingsRoute = lazy(() =>
	import('./quantum_ticketing/Chatbot/ChatbotSettings'),
);
const HomePage = lazy(() => import('./quantum_ticketing/HomePage'));
const AgentChatSupport = lazy(() => import('src/components/AgentChatSupport'));
const SatisfactionSurveys = lazy(() =>
	import('./quantum_ticketing/Admin/modules/SatisfactionSurvey'),
);
const AddOrEditSatisfactionSurvey = lazy(() =>
	import(
		'./quantum_ticketing/Admin/modules/SatisfactionSurvey/AddOrEditSatisfactionSurvey'
	),
);
const SurveyPage = lazy(() => import('./quantum_ticketing/SurveyPage'));

const App = () => {
	
	return (
		<>
			<Alert />
			<Suspense
				fallback={
					<div className="p-3 mt-3 d-flex justify-content-center">
						<ClipLoader color="#009FDF" />
					</div>
				}
			>
				<BrowserRouter>
					<GlobalWrapper>
						<Routes>
							<Route
								element={<GeneralNavigation />}
								path="/mainpage"
							>
								<Route
									element={
										<GuardedRoute>
											<Tickets />
										</GuardedRoute>
									}
									path="tickets"
								/>
								<Route element={<Outlet />} path="incidents">
									<Route
										element={<AssignIncidentsPage />}
										path="assign-incident"
									/>
									<Route
										element={<IncidenceResponseLayout />}
										path=":id"
									>
										<Route
											element={
												<GuardedRoute>
													<CaseDetails />
												</GuardedRoute>
											}
											path="case-details"
										/>
										<Route
											element={
												<GuardedRoute>
													<CaseDiscussion />
												</GuardedRoute>
											}
											path="case-discussion"
										/>
										<Route
											element={
												<GuardedRoute>
													<CaseParticipants />
												</GuardedRoute>
											}
											path="case-members"
										/>
										<Route
											element={
												<GuardedRoute>
													<Attachments />
												</GuardedRoute>
											}
											path="attachments"
										/>
										<Route
											element={
												<GuardedRoute>
													<Tasks />
												</GuardedRoute>
											}
											path="tasks"
										/>
										<Route
											element={
												<GuardedRoute>
													<CaseNotes />
												</GuardedRoute>
											}
											path="case-notes"
										/>
										<Route
											element={
												<GuardedRoute>
													<Synopsis />
												</GuardedRoute>
											}
											path="synopsis"
										/>
										<Route
											element={
												<GuardedRoute>
													<GeneralCaseInformation />
												</GuardedRoute>
											}
											index
										/>
									</Route>
									<Route element={<Incidents />} index />
								</Route>
								<Route
									element={<ChatbotSettingsRoute />}
									path="chatbot"
								/>
								<Route
									element={<AgentChatSupport />}
									path="chat-support"
								/>
								<Route
									element={
										<GuardedRoute>
											<UserSideBar />
										</GuardedRoute>
									}
									path="update-profile"
								/>

								<Route
									element={
										<GuardedRoute
											excludeRole={RolesList.EndUser}
										>
											<Users />
										</GuardedRoute>
									}
									path="users"
								/>
								<Route
									element={
										<GuardedRoute
											excludeRole={RolesList.EndUser}
										>
											<Views />
										</GuardedRoute>
									}
									path="views"
								></Route>
								<Route
									path="add-view"
									element={
										<GuardedRoute
											excludeRole={RolesList.EndUser}
										>
											<AddEditView />
										</GuardedRoute>
									}
								/>
								<Route
									path="edit-view/:id"
									element={
										<GuardedRoute
											excludeRole={RolesList.EndUser}
										>
											<AddEditView />
										</GuardedRoute>
									}
								/>
								<Route
									element={
										<GuardedRoute
											excludeRole={RolesList.EndUser}
										>
											<Reporting />
										</GuardedRoute>
									}
									path="reports"
								/>

								<Route
									path="document-center"
									element={<DocumentCenterRoute />}
								>
									<Route
										element={
											<GuardedRoute>
												<DocumentCenter />
											</GuardedRoute>
										}
										index
									/>
								</Route>
								<Route path="faq" element={<FaqRoute />}>
									<Route
										element={
											<FAQArticle type="admin-portal" />
										}
										path=":id"
									/>

									<Route element={<AddFolder />} path="add" />
									<Route
										element={<AddSubFolder />}
										path="add-sub"
									/>

									<Route
										element={<AddFaq />}
										path="add-faq"
									/>
									<Route
										element={<AddFaq type={'edit'} />}
										path="edit-faq/:id"
									/>
									<Route
										element={<ReviewFaq type={'edit'} />}
										path="review-faq/:id"
									/>
									<Route
										element={<AddFolder type={'edit'} />}
										path="edit-folder/:id"
									/>
									<Route
										element={<AddSubFolder type={'edit'} />}
										path="edit-subfolder/:id"
									/>
									<Route
										element={<FaqChangeRequests />}
										path="change-request"
									/>
									<Route
										element={
											<GuardedRoute>
												<Faq />
											</GuardedRoute>
										}
										index
									/>
								</Route>

								<Route
									element={
										<GuardedRoute
											excludeRole={RolesList.EndUser}
										>
											<AdminOptionsDisplay />
										</GuardedRoute>
									}
									path="admin-dashboard"
								>
									<Route element={<Outlet />} path="admin">
										<Route
											path="emails"
											element={<Emails />}
										/>
										<Route
											path="groups"
											element={<Outlet />}
										>
											<Route
												path="create-group"
												element={
													<AddGroup type="create" />
												}
											/>
											<Route
												path="edit-group/:id"
												element={
													<AddGroup type="edit" />
												}
											/>
											<Route index element={<Groups />} />
										</Route>
										<Route
											path="roles"
											element={<Outlet />}
										>
											<Route
												path="create-role"
												element={
													<AddRole type="create" />
												}
											/>
											<Route
												path="edit-role/:id"
												element={
													<AddRole type="edit" />
												}
											/>
											<Route index element={<Roles />} />
										</Route>
										<Route
											path="agents"
											element={<Outlet />}
										>
											<Route
												path="create-agent"
												element={
													<AgentsEditOrCreate type="create" />
												}
											/>
											<Route
												path="edit-agent/:id"
												element={
													<AgentsEditOrCreate type="edit" />
												}
											/>
											<Route index element={<Agents />} />
										</Route>
										<Route
											path="automation"
											element={<Outlet />}
										>
											<Route
												index
												element={<Automation />}
											/>
											<Route
												path="add/creation_rule"
												element={<AddCreationRule />}
											/>
											<Route
												path="add/change_rule"
												element={<AddChangeRule />}
											/>
											<Route
												path="add"
												element={
													<AddEditAutomationRule />
												}
											/>
											<Route
												path="edit/:id"
												element={
													<AddEditAutomationRule />
												}
											/>
										</Route>
										<Route
											path="custom-fields"
											element={<Outlet />}
										>
											<Route
												index
												element={<CustomFields />}
											/>
											<Route
												path="add/*"
												element={<AddField />}
											/>
											<Route
												path="edit/*"
												element={<EditField />}
											/>
										</Route>
										<Route
											path="custom-form"
											element={<CustomForm />}
										/>
										<Route
											path="custom-form/add"
											element={
												<AddCustomForm mode="create" />
											}
										/>
										<Route
											path="custom-form/edit"
											element={
												<AddCustomForm mode="edit" />
											}
										/>
										<Route
											path="business"
											element={<Outlet />}
										>
											<Route
												path="create-sla"
												element={
													<CreateSLA type="create" />
												}
											/>
											<Route
												path="edit-sla"
												element={
													<CreateSLA type="edit" />
												}
											/>
											<Route
												path="create-business-hours"
												element={
													<CreateBusinessHours type="create" />
												}
											/>
											<Route
												path="edit-business-hours"
												element={
													<CreateBusinessHours type="edit" />
												}
											/>
											<Route
												index
												element={<BusinessRules />}
											/>
										</Route>
										<Route
											path="satifaction-surveys"
											element={<Outlet />}
										>
											<Route
												path="create"
												element={
													<AddOrEditSatisfactionSurvey action="create" />
												}
											/>
											<Route
												path="edit/:id"
												element={
													<AddOrEditSatisfactionSurvey action="edit" />
												}
											/>
											<Route
												index
												element={
													<SatisfactionSurveys />
												}
											/>
										</Route>
										<Route
											element={
												<RedirectRoute
													to={
														'/mainpage/admin-dashboard/admin/emails'
													}
													replace={true}
												/>
											}
											index
										/>
									</Route>
									<Route element={<Outlet />} path="advanced">
										<Route
											path="sign-in-options"
											element={<SignInOptions />}
										/>
										<Route
											path="single-sign-on-user"
											element={<Outlet />}
										>
											<Route
												path="create"
												element={
													<AddorUpdateSSO type="create" />
												}
											/>
											<Route
												path="edit/:id"
												element={
													<AddorUpdateSSO type="edit" />
												}
											/>
											<Route
												index
												element={<SingleSignOn />}
											/>
										</Route>
										<Route
											path="workflows"
											element={<Outlet />}
										>
											<Route
												index
												element={<Workflows />}
											/>
										</Route>
										<Route
											path="security"
											element={<Security />}
										/>
										<Route
											element={
												<RedirectRoute
													to={
														'/mainpage/admin-dashboard/advanced/sign-in-options'
													}
													replace={true}
												/>
											}
											index
										/>
									</Route>
									<Route element={<Outlet />} path="config">
										<Route
											path="satisfaction-survey-settings"
											element={
												<SatisfactionSurveySettings />
											}
										/>
										<Route
											path="whitelist-blacklist"
											element={<WhiteListBlackList />}
										/>
										<Route
											element={
												<RedirectRoute
													to={
														'/mainpage/admin-dashboard/config/whitelist-blacklist'
													}
													replace={true}
												/>
											}
											index
										/>
									</Route>
									<Route
										element={<Outlet />}
										path="enduserportal"
									>
										<Route
											path="configurations"
											element={
												<EndUserPortalConfigurations />
											}
										/>
										<Route
											path="widgets"
											element={
												<p>No widgets available</p>
											}
										/>
										<Route
											path="announcements"
											element={<Outlet />}
										>
											<Route
												path="edit/:id"
												element={
													<AddOrEditAnnouncements type="edit" />
												}
											/>
											<Route
												path="create"
												element={
													<AddOrEditAnnouncements type="create" />
												}
											/>
											<Route
												index
												element={<Announcements />}
											/>
										</Route>
										<Route
											element={
												<RedirectRoute
													to={
														'/mainpage/admin-dashboard/enduserportal/configurations'
													}
													replace={true}
												/>
											}
											index
										/>
									</Route>
									<Route
										element={<Outlet />}
										path="productivity"
									>
										<Route
											path="quick-responses*"
											element={<QuickResponse />}
										/>
										<Route path="tags" element={<Tags />} />
										<Route
											element={
												<RedirectRoute
													to={
														'/mainpage/admin-dashboard/productivity/quick-responses'
													}
													replace={true}
												/>
											}
											index
										/>
									</Route>
									<Route
										element={
											<RedirectRoute
												to={
													'/mainpage/admin-dashboard/admin/emails'
												}
												replace={true}
											/>
										}
										index
									/>
								</Route>

								<Route
									element={
										<GuardedRoute
											excludeRole={RolesList.EndUser}
										>
											<SuperAdmin />
										</GuardedRoute>
									}
									path="super-admin"
								>
									<Route
										path="enterprises"
										element={<Outlet />}
									>
										<Route
											path="new-enterprise"
											element={<AddEditEnterprise />}
										/>
										<Route
											path="edit-enterprise/:id"
											element={
												<AddEditEnterprise type="edit" />
											}
										/>
										<Route
											index
											element={<Enterprises />}
										/>
									</Route>
									<Route
										path="default-fields"
										element={<Outlet />}
									>
										<Route
											index
											element={<DefaultFields />}
										/>
										<Route
											path="edit/*"
											element={<EditDefaultField />}
										/>
										<Route
											path="add/*"
											element={<AddDefaultField />}
										/>
									</Route>
									<Route path="agents" element={<Outlet />}>
										<Route
											path="create-agent"
											element={
												<SuperAdminAgentsEditOrCreate type="create" />
											}
										/>
										<Route
											path="edit-agent/:id"
											element={
												<SuperAdminAgentsEditOrCreate type="edit" />
											}
										/>
										<Route
											index
											element={<SuperAdminAgents />}
										/>
									</Route>
									<Route
										element={
											<RedirectRoute
												to={
													'/mainpage/super-admin/enterprises'
												}
												replace={true}
											/>
										}
										index
									/>
								</Route>
								<Route
									element={<TicketDetailsLayout />}
									path="ticket/:id"
								>
									<Route
										element={<AdditionalTicketDetails />}
										path="info"
									/>
									<Route
										element={<TicketMessaging />}
										path="messages"
									/>
									<Route
										element={<TicketAttachments />}
										path="attachments"
									/>
									<Route
										element={<GeneralTicketDetails />}
										path='general-info'

									/>
									<Route
										element={<Navigate to="messages"/>}
										index
									/>
								</Route>
								<Route
									element={
										<GuardedRoute>
											<TicketHistoryPage />
										</GuardedRoute>
									}
									path="ticket-history/:id/:readableId"
								/>
								<Route
									element={
										<GuardedRoute>
											<UserPage />
										</GuardedRoute>
									}
									path="user/:id"
								/>
								<Route
									element={
										<GuardedRoute>
											<AssignTicketPage />
										</GuardedRoute>
									}
									path="assign-ticket"
								/>
								<Route
									element={
										<GuardedRoute>
											<TicketWorkflowListPage />
										</GuardedRoute>
									}
									path="ticket-workflow-list"
								/>
								<Route
									element={
										<GuardedRoute>
											<TicketWorkflowExecutionListPage />
										</GuardedRoute>
									}
									path="ticket-workflow-executions/:id"
								/>
								<Route
									element={
										<GuardedRoute>
											<TicketWorkflowRenderPage />
										</GuardedRoute>
									}
									path="ticket-workflow-execution-log/:id"
								/>
								<Route
									element={
										<GuardedRoute>
											<AddTicket type={0} />
										</GuardedRoute>
									}
									path="add-ticket"
								/>
								<Route
									element={
										<GuardedRoute>
											<AddTicket type={1} />
										</GuardedRoute>
									}
									path="add-incident"
								/>
								<Route
									element={<AgentFAQPage />}
									path="faq-category/:id"
								/>
								<Route
									element={
										<GuardedRoute>
											<HomePage />
										</GuardedRoute>
									}
									index
								/>
							</Route>
							<Route
								element={<AccessDenied />}
								path="/access-denied"
							/>
							<Route element={<NotFound />} path="/not-found" />
							<Route
								element={<AuthScreen type="login" />}
								path="/login"
							/>
							<Route
								element={<IdpSignIn />}
								path="/idp-login-success"
							/>
							<Route
								element={<IdpSignIn />}
								path="/idp-login-failure"
							/>
							<Route
								element={<SSOCallback />}
								path="/sso-callback"
							/>
							<Route
								element={<SSOSiginin />}
								path="/sso-signin"
							/>
							<Route element={<SendLink />} path="/send-link" />
							<Route
								element={<ChangePassword />}
								path="/change-password/:id"
							/>
							<Route
								element={
									<GuardedRoute>
										<EndUserHomePage />
									</GuardedRoute>
								}
								path="/"
							/>
							<Route
								element={<FAQPage />}
								path="/faq-category/:id"
							/>
							<Route
								element={<FAQArticle />}
								path="/faq-article/:id"
							/>

							<Route
								path="/view-announcement"
								element={<AnnouncementView />}
							/>

							<Route
								element={<EndUserTicket key={0} type={0} />}
								path="/portal/ticket"
							/>
							<Route
								element={<EndUserTicket key={1} type={1} />}
								path="/portal/incidents"
							/>
							<Route
								element={<AuthScreen type="signup" />}
								path="/portal/sign-up"
							/>
							<Route
								element={<ActivateAccount />}
								path="/portal/activate/:id"
							/>
							<Route
								element={<ActiveSecondaryEmail />}
								path="/portal/activate-se/:id"
							/>
							<Route
								element={<AuthScreen type="login" />}
								path="/portal/login"
							/>
							<Route
								path="/survey/:id"
								element={<SurveyPage />}
							/>
							<Route
								element={<Navigate to="/not-found" />}
								path="*"
							/>
						</Routes>
					</GlobalWrapper>
				</BrowserRouter>
			</Suspense>
		</>
	);
};

export default App;
