import { BehaviorSubject } from 'rxjs';
import config from 'src/config';
import { jwtDecode } from 'jwt-decode';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { alertService } from './alert.service';
import { utilityService } from './utility.service';
import Cookies from 'js-cookie';

const token$ = new BehaviorSubject();
const profile$ = new BehaviorSubject();
const settings$ = new BehaviorSubject(
	JSON.parse(localStorage.getItem('enterprise-settings')),
);
const roles$ = new BehaviorSubject();
const view$ = new BehaviorSubject(localStorage.getItem('view'));

const onTokenUpdate = () => {
	return token$.asObservable();
};

const login = async (email, password, isEndUserLogin, eid) => {
	let response = await fetchWrapper.post(
		isEndUserLogin && eid
			? `${config.apiURL}/auth/contact-login`
			: `${config.apiURL}/auth/agent-login`,
		{ email, password, eid },
	);

	if (response.access_token) {
		await saveToken(response.access_token);
		return response;
	}
};

const saveToken = async (token) => {
	if (token) {
		token$.next(token);
		const decoded = jwtDecode(token);
		profile$.next(decoded);
	}
};

const getSSOProfiles = async (enterpriseId, needSAMLRequest = false) => {
	let response = await fetchWrapper.post(
		`${config.apiURL}${config.getSSODetails}`,
		{ enterpriseId, needSAMLRequest },
	);

	return response?.data ?? [];
};

const getSignInOptions = async (enterpriseId) => {
	let response = await fetchWrapper.post(
		`${config.apiURL}${config.getSignInOptions}`,
		{ enterpriseId },
	);

	return response?.data ?? '';
};

function logout() {
	let domainParts = window.location.hostname.split('.');

	while (domainParts.length > 0) {
		let domain = domainParts.join('.');
		Cookies.remove(config.portalTokenKey, { path: '/', domain: domain });
		domainParts.shift();
	}
	Cookies.remove(config.tokenKey);
	Cookies.remove(config.impersonateToken);
	window.location.reload();
}

function setSettings(settings) {
	settings$.next(settings);
}

function setProfile(profile) {
	profile$.next(profile);
}

function setRoles(roles) {
	roles$.next(roles);
}

function checkForRolePermitted(roleName, enterpriseId) {
	// Root user Tresspassing
	if (roles$.value?.some((ent) => ent?.permissions?.includes('Root.Yes'))) {
		return true;
	}

	// If Role Check Required for one Enterprise
	if (enterpriseId) {
		const enterpriseRole = roles$.value.find(
			(e) => e.enterpriseId === enterpriseId,
		);
		if (enterpriseRole) {
			return (
				enterpriseRole?.permissions?.includes('Admin.CanView') ||
				enterpriseRole?.permissions?.includes(roleName)
			);
		}
		return false;
	}

	return roles$.value?.some((ent) => ent?.permissions?.includes(roleName));
}

const verifySSO = async (payload) => {
	return await fetchWrapper.post(
		`${config.apiURL}${config.ssoVerifyUrl}`,
		payload,
	);
};


const getCountOfEnterpriseForTheRole = (role = 'Admin.CanView') => {
	let enterprise = roles$.value?.filter((ent) =>
		ent.permissions?.includes(role),
	);
	return { count: enterprise.length, enterprises: enterprise };
};

export const profileService = {
	login,
	logout,
	setSettings,
	setProfile,
	setViewId,
	checkForRolePermitted,
	getCountOfEnterpriseForTheRole,
	get token() {
		return token$.value;
	},
	get profile() {
		return profile$.value;
	},
	get isAdmin() {
		return (
			roles$.value?.some((ent) =>
				ent.permissions?.includes('Root.Yes'),
			) ||
			roles$.value?.some((ent) =>
				ent.permissions?.includes('Admin.CanView'),
			)
		);
	},
	get isSuperAdmin() {
		return roles$.value?.some((ent) =>
			ent.permissions?.includes('Root.Yes'),
		);
	},
	get isAgent() {
		return (
			roles$.value?.some((ent) =>
				ent.permissions?.includes('Root.Yes'),
			) ||
			roles$.value?.some((ent) => ent.permissions?.includes('Agents.Yes'))
		);
	},
	get isEndUser() {
		return roles$.value?.some((ent) =>
			ent.permissions?.includes('EndUser.Yes'),
		);
	},
	get settings() {
		return settings$.value;
	},
	get getViewId() {
		return view$.value ?? '';
	},
	getSSOProfiles,
	getSignInOptions,
	saveToken,
	verifySSO,
	onTokenUpdate,
	setRoles,
};

function setViewId(viewId) {
	localStorage.setItem('view', viewId);
	view$.next(viewId);
}
